import { Listenable } from "../../../bud-lite-tv/src/lib";
import { Request } from "../../datas/request";
import { PlayerState } from "../../libs/player";
import { Didomi } from "../cmp/didomi";

export enum TypeEventEStat {
  state = "state",
  polling = "polling",
}

export enum PlayerStateEStat {
  play = 5,
  pause = 7,
  stop = 12,
}
export class EStat {
  private host: string | null = null;
  private cmsVI: string | null = null;
  private version = 0;
  private indexPolling = 0;
  private secondToLastTime = 0;
  private polling: number | null = null;
  private INTERVAL_POLLING = 60000;
  private time = [10000, 10000, 20000, 20000, this.INTERVAL_POLLING];
  private request;
  private isEnable = false;
  private uuid = localStorage.getItem("osn_device_id");
  public isPauseMidroll = false;

  constructor() {
    this.request = new Request();
    this.isEnable = Didomi.isVendorAllowedToTrack("mediametri-Npn8xCFG");
  }

  public auth(serial: string, callback: () => void) {
    this.request.fetchTxtJson(`https://auth.estat.com/${serial}.js`).subscribe(
      async (text: any) => {
        const result = text.split(";");
        this.host = await result[1].split("=")[1].replace(/"/g, "");
        this.cmsVI = await result[2].split("=")[1].replace(/"/g, "");
        callback();
      },
      (error: any) => {
        console.error("[eStat] Error: auth()", error);
      },
      () => {}
    );
  }

  public sendState(
    data: any,
    playerState: PlayerState,
    currentTime: number,
    id: string,
    isAds: Listenable<boolean>
  ): void {
    const state = this.playerState(playerState);
    if (this.cmsVI && state && !isAds.value && Math.round(data.streamDuration) !== Math.round(currentTime)) {
      const query = this.formatQuery(data, state, currentTime, id, TypeEventEStat.state);
      if (state === PlayerStateEStat.play) {
        this.isPauseMidroll = false;
      }
      this.sendRequest(data.serial, query);
    } else {
      console.error("[eStat] Error: sendState() cmsVI =", this.cmsVI);
    }
  }

  public sendPauseMidroll(data: any, currentTime: number, id: string, isAds: Listenable<boolean>) {
    if (this.cmsVI && !isAds.value && !this.isPauseMidroll) {
      const query = this.formatQuery(data, PlayerStateEStat.pause, currentTime, id, TypeEventEStat.state);
      this.sendRequest(data.serial, query);
      this.isPauseMidroll = true;
    } else {
      console.error("[eStat] Error: sendPauseMidroll() cmsVI =", this.cmsVI);
    }
  }

  public sendStop(data: any, currentTime: number, id: string) {
    if (this.cmsVI) {
      const query = this.formatQuery(data, PlayerStateEStat.stop, currentTime, id, TypeEventEStat.state);
      this.sendRequest(data.serial, query);
      this.isPauseMidroll = true;
    } else {
      console.error("[eStat] Error: sendPauseMidroll() cmsVI =", this.cmsVI);
    }
  }

  public createPolling(
    data: any,
    currentTime: Listenable<number>,
    id: string,
    playerState: Listenable<PlayerState>,
    isAds: Listenable<boolean>
  ) {
    /*
     ** Time in milliseconds for send polling in first minute, after this minute send polling every minute.
     */
    this.polling = window.setTimeout(
      () => this.recursivePolling(data, currentTime, id, playerState, isAds),
      this.indexPolling < this.time.length ? this.time[this.indexPolling] : this.INTERVAL_POLLING
    );
  }

  private recursivePolling(
    data: any,
    currentTime: Listenable<number>,
    id: string,
    playerState: Listenable<PlayerState>,
    isAds: Listenable<boolean>
  ) {
    /*
     ** For the first minute of the content, we send polling hits at 10, 20, 40 and 60sec then every minute.
     ** The condition to stop the recursion is when the player clears the setTimeout before a new recursion
     */
    if (this.clearPolling()) {
      const state = this.playerState(playerState.value);
      if (this.cmsVI && state && !isAds.value) {
        const query = this.formatQuery(data, state, currentTime.value, id, TypeEventEStat.polling);
        this.sendRequest(data.serial, query);
      } else {
        console.error("[eStat] Error: sendPolling()");
      }
      this.indexPolling++;
      this.polling = window.setTimeout(
        () => this.recursivePolling(data, currentTime, id, playerState, isAds),
        this.indexPolling < this.time.length ? this.time[this.indexPolling] : this.INTERVAL_POLLING
      );
    }
  }

  public clearPolling() {
    if (this.polling) {
      clearTimeout(this.polling);
      return true;
    }
    return false;
  }

  private sendRequest(serial: string, query: any) {
    this.secondToLastTime = query.cmsPO;
    this.version++;
    this.request.fetchTxtJson(`${this.host}${serial}`, "GET", query).subscribe(
      () => {},
      (error: any) => {
        console.error("[eStat] Error: sendRequest()", error);
      },
      () => {}
    );
  }

  private playerState(state: PlayerState) {
    switch (state) {
      case PlayerState.PLAYING:
        return PlayerStateEStat.play;
      case PlayerState.PAUSED:
        return PlayerStateEStat.pause;
      case PlayerState.ENDED:
        return PlayerStateEStat.stop;
      default:
        return null;
    }
  }

  private formatQuery(
    data: any,
    playerState: PlayerStateEStat,
    currentTime: number,
    id: string,
    typeEventEStat: TypeEventEStat
  ) {
    return {
      cmsVI: this.cmsVI,
      cmsPL: data.newLevel10,
      cmsEV: typeEventEStat,
      cmsPS: playerState,
      cmsMV: this.version,
      cmsSN: data.streamName,
      cmsPO: Math.round(currentTime),
      cmsOP: this.secondToLastTime,
      // uid: null,
      ct: this.isEnable ? "optin" : "exempted",
      cmsDU: data.streamDuration,
      cmsS1: data.level3,
      cmsS2: data.level2,
      cmsS3: data.level1,
      cmsS4: data.level4,
      cmsS5: null,
      cmsGR: data.streamGenre,
      ml1: data.newLevel1,
      ml2: data.newLevel2,
      ml3: data.newLevel3,
      ml4: data.newLevel4,
      ml5: data.newLevel5,
      ml6: data.newLevel6,
      ml7: data.newLevel7,
      ml8: data.newLevel8,
      ml9: null, //data.newLevel9,
      ml10: data.newLevel10,
      ml11: data.newLevel11,
      msCid: data.mediaContentId,
      msDm: data.mediaDiffMode,
      msCh: data.mediaChannel,
      cmsRK: this.version,
    };
  }
}
