import { z } from "zod";

import { platform } from "../../../bud-lite-tv/src/lib";
import { Plugin } from "../../datas/plugin";
import { AppPurposeList, AppVendorList, getVendorPurpose, PurposeId, VendorId } from "./didomiStub";
import { getVisitorMode, setVisitorMode } from "./visitorMode";

export enum Position {
  allow = "ALLOW",
  denied = "DENIED",
}

const DidomiAccessToken = z.object({
  access_token: z.string(),
  key: z.string(),
  type: z.literal("api-key"),
});

const Vendors = z.object({
  enabled: z.array(z.string()),
  disabled: z.array(z.string()),
});
type Vendors = z.infer<typeof Vendors>;
const AppVendors = z.object({
  enabled: z.array(VendorId),
  disabled: z.array(VendorId),
});
export type AppVendors = z.infer<typeof AppVendors>;

const Purpose = z.object({
  id: z.string(),
  enabled: z.boolean(),
});
type Purpose = z.infer<typeof Purpose>;
const AppPurpose = z.object({
  id: PurposeId,
  enabled: z.boolean(),
});
type AppPurpose = z.infer<typeof AppPurpose>;

const Consents = z.object({
  purposes: z.array(Purpose),
  vendors: Vendors,
});
type Consents = z.infer<typeof Consents>;
export type AppConsents = {
  purposes: AppPurpose[];
  vendors: AppVendors;
};

class DidomiImplementation {
  private _accessToken: { value: string; expireAtMs: number } | null = null;
  private _consents: AppConsents | null = null;
  private _updatedDate: Date | null = null;

  private static _instance: DidomiImplementation | null = null;
  public static create() {
    if (DidomiImplementation._instance === null) {
      DidomiImplementation._instance = new DidomiImplementation();
    }
    return DidomiImplementation._instance;
  }

  private constructor() {}

  private _genericDidomiFetch = async <T>(callback: (accessToken: string) => Promise<T | null>) => {
    if (this._accessToken === null || Date.now() > this._accessToken.expireAtMs) {
      const accessToken = await this._generateAccessToken();
      if (accessToken === null) {
        return null;
      } else {
        this._accessToken = {
          expireAtMs: Date.now() + 60 * 60 * 1000, // one hour validity
          value: accessToken,
        };
      }
    }

    try {
      return await callback(this._accessToken.value);
    } catch (e: unknown) {
      Log.app.error(e);
      return null;
    }
  };

  private async _generateAccessToken(): Promise<string | null> {
    try {
      const json = await Plugin.getInstance().createDidomiAccessToken().toPromise();
      const didomiAccessToken = DidomiAccessToken.parse(json);
      return didomiAccessToken.access_token;
    } catch (e) {
      Log.api.error(e);
      return null;
    }
  }

  /**
   * Fetch user content and set local user consent data
   * @returns user consents
   */
  public getUserConsent = async () => {
    const id = this._getUserId();

    return this._genericDidomiFetch(
      async (accessToken: string): Promise<Consents | null> => {
        const json = await Plugin.getInstance().getDidomiConsents(id, accessToken).toPromise();

        const UserConsents = z.object({
          updated_at: z
            .string()
            .nullable()
            .transform(d => (d !== null ? new Date(d) : null)),
          consents: Consents,
        });
        const UsersConsentsResponse = z.object({
          data: z.array(UserConsents),
        });

        const parseResponseResult = UsersConsentsResponse.safeParse(json);
        const usersConsentsResponse = parseResponseResult.success ? parseResponseResult.data : null;

        if (usersConsentsResponse !== null && usersConsentsResponse.data.length >= 1) {
          const userConsent = usersConsentsResponse.data[0];
          this._updatedDate =
            userConsent.updated_at === null || isNaN(userConsent.updated_at.getTime()) ? null : userConsent.updated_at;

          const purposes = userConsent.consents.purposes.filter((p: Purpose): p is AppPurpose => {
            return AppPurpose.safeParse(p).success;
          });
          const enabled = userConsent.consents.vendors.enabled.filter((p: string): p is VendorId => {
            return VendorId.safeParse(p).success;
          });
          const disabled = userConsent.consents.vendors.disabled.filter((p: string): p is VendorId => {
            return VendorId.safeParse(p).success;
          });

          this._consents = {
            purposes,
            vendors: {
              enabled,
              disabled,
            },
          };
          return this._consents;
        }

        this._consents = null;
        return this._consents;
      }
    );
  };

  public isPurposeEnabled = (purposeId: PurposeId): boolean => {
    return this._consents?.purposes.find(purpose => purpose.id === purposeId)?.enabled ?? false;
  };

  public isVendorEnabled = (vendorId: VendorId): boolean => {
    return this._consents?.vendors.enabled.find(v => v === vendorId) !== undefined;
  };

  /**
   * Check if vendorId and his corresponding purpose is enabled.
   * Should be used to check if a vendor is allowed to track private user data
   * @param vendorId
   * @returns
   */
  public isVendorAllowedToTrack = (vendorId: VendorId): boolean => {
    const purposeId = getVendorPurpose(vendorId);

    if (this._consents === null || purposeId === null) {
      return false;
    } else {
      const isPurposeEnable = this.isPurposeEnabled(purposeId);

      if (isPurposeEnable) {
        let isVendorEnabled = false;
        for (const enabledVendor of this._consents.vendors.enabled) {
          if (enabledVendor === vendorId) {
            isVendorEnabled = true;
          }
        }
        return isVendorEnabled;
      } else {
        return false;
      }
    }
  };

  public shouldUserBePromptedCmp(): boolean {
    if (this._consents === null) {
      /**
       * consents has failed to be fetched
       * meaning that we should show consents
       */
      return true;
    } else {
      /**
       * Handling consent expiration
       */
      if (this._updatedDate !== null) {
        const currentDate = new Date();

        // We need to display the CMP page if the last update date is higher than 6 months
        if (Math.abs(currentDate.getTime() - this._updatedDate.getTime()) > 6 * 30 * 24 * 60 * 60 * 1000) {
          return true;
        }
      }

      /**
       * checking if all the vendors / purposes are defined
       */
      const vendorsSet = new Set(AppVendorList);
      for (const mode of ["disabled", "enabled"] as const) {
        for (const vendor of this._consents.vendors[mode]) {
          vendorsSet.delete(vendor);
        }
      }

      const purposeSet = new Set(AppPurposeList);
      for (const purpose of this._consents.purposes) {
        purposeSet.delete(purpose.id);
      }

      return vendorsSet.size !== 0 || purposeSet.size !== 0;
    }
  }

  public enableAll = async () => {
    //if enable all then "traceurs" can't be refused
    setVisitorMode("enabled");
    await this._updateDidomiConsents({
      purposes: AppPurposeList.map(purpose => ({ id: purpose, enabled: true })),
      vendors: {
        enabled: AppVendorList.map(vendor => vendor),
        disabled: [],
      },
    });
  };

  public disableAll = async () => {
    await this._updateDidomiConsents({
      purposes: AppPurposeList.map(purpose => ({ id: purpose, enabled: false })),
      vendors: {
        enabled: [],
        disabled: AppVendorList.map(vendor => vendor),
      },
    });
  };

  private _updateDidomiConsents = async (consents: AppConsents) => {
    if (getVisitorMode().value === "disabled") {
      // reference list of vendors and purposes link to visitor mode
      const visitorModeVendorIds: VendorId[] = ["atinterne-D22mcTNf"];
      const visitorModePurposeIds: PurposeId[] = ["mesureda-LjJ4eyi4"];

      // override the consents
      // update the purposes with the visitor restriction
      for (const visitorModePurposeId of visitorModePurposeIds) {
        const purposeToDisable = consents.purposes.find(purpose => purpose.id === visitorModePurposeId);
        if (purposeToDisable !== undefined) {
          purposeToDisable.enabled = false;
        }
      }

      // update the vendors with the visitor restriction
      for (const vendorIdToDisable of visitorModeVendorIds) {
        consents.vendors.enabled = consents.vendors.enabled.filter(vendorId => {
          return vendorId !== vendorIdToDisable;
        });
        consents.vendors.disabled = consents.vendors.disabled.filter(vendorId => {
          return vendorId !== vendorIdToDisable;
        });
        consents.vendors.disabled.push(vendorIdToDisable);
      }
    }

    this._consents = consents;
    return this._genericDidomiFetch(
      async (accessToken: string): Promise<void> => {
        await Plugin.getInstance().updateDidomiConsents(this._getUserId(), consents, accessToken).toPromise();
      }
    );
  };

  public updateUserConsents = async (newPartialConsents: Partial<AppConsents>) => {
    // Purposes
    const fullConsents: AppConsents = {
      purposes: [],
      vendors: {
        enabled: [],
        disabled: [],
      },
    };

    for (const purposeId of AppPurposeList) {
      fullConsents.purposes.push({
        id: purposeId,
        enabled:
          newPartialConsents.purposes?.find(p => p.id === purposeId)?.enabled ?? this.isPurposeEnabled(purposeId),
      });
    }

    for (const vendorId of AppVendorList) {
      if (newPartialConsents.vendors?.enabled.find(v => vendorId === v) !== undefined) {
        fullConsents.vendors.enabled.push(vendorId);
      } else {
        if (newPartialConsents.vendors?.disabled.find(v => vendorId === v) !== undefined) {
          fullConsents.vendors.disabled.push(vendorId);
        } else {
          // if vendorId is not in enabled and not in disabled, we use the "current app value"
          if (this.isVendorEnabled(vendorId)) {
            fullConsents.vendors.enabled.push(vendorId);
          } else {
            fullConsents.vendors.disabled.push(vendorId);
          }
        }
      }
    }

    return await this._updateDidomiConsents(fullConsents);
  };

  sendCurrentUserConsents = () => {
    if (this._consents !== null) {
      this._updateDidomiConsents(this._consents);
    }
  };

  private _getUserId = () => {
    return platform.deviceId;
  };
}

export const Didomi = DidomiImplementation.create();
