import "./moreInformations.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "../../../../bud-lite-tv/src/lib";
import { navigationStack } from "../../../main";
import { sendPianoAnalytic } from "../../../tools/analytics/piano";
import { Position } from "../../../tools/cmp/didomi";
import { getVisitorMode, setVisitorMode } from "../../../tools/cmp/visitorMode";
import { Arrow } from "../consentManagement/arrowConsentManagement";

enum TextPosition {
  top = "244px",
  middle = "-500px",
  low = "-1240px",
}

interface Toggle {
  state: Position;
}

class ToggleCmp extends View {
  private toggle: Toggle;
  private img;
  private toggleBox;

  private readonly imgAllow = `url(${require("~images/checkBox/check.png")})`;

  constructor(toggle: Toggle) {
    super(DOMHelper.createDivWithParent(null, "ToggleCmp", null, null));
    this.toggle = toggle;

    this.toggleBox = DOMHelper.createDivWithParent(this.rootElement, "ToggleCmpBox");
    this.img = DOMHelper.createDivImg(this.toggleBox, "ImgToggle", null, null);
    getVisitorMode().value === "disabled" ? this.deniedToggle() : this.allowToggle();
    DOMHelper.createDivWithParent(
      this.rootElement,
      "ToggleCmpText",
      null,
      "Je m'oppose au dépôt du traceur de mesure d'audience"
    );
  }

  allowToggle = () => {
    if (this.img) {
      this.img.style.backgroundImage = "";
      this.toggleBox.style.background = "#191e25";
      this.toggle.state = Position.allow;
    }
  };

  deniedToggle = () => {
    if (this.img) {
      this.img.style.backgroundImage = this.imgAllow;
      this.toggleBox.style.background = "#c8cacb";
      this.img.style.backgroundRepeat = "no-repeat";
      this.img.style.top = "18px";
      this.img.style.left = "12px";
      this.toggle.state = Position.denied;
    }
  };
}

class ButtonSave extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, "ButtonSave"));
    DOMHelper.createDivWithParent(this.rootElement, "Text", null, "Enregistrer mon choix");
  }
}

class ListToggle extends View {
  private _list;
  private _toggle: Toggle = { state: Position.denied };

  constructor(onButtonSave: () => void) {
    super(DOMHelper.createDivWithParent(null, "ListToggle"));
    const toggleCmpView = new ToggleCmp(this._toggle);
    this._list = this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource<"Toggle" | "Button">(["Toggle", "Button"]),
      viewFactory: model => {
        switch (model) {
          case "Toggle":
            return toggleCmpView;
          case "Button":
            return new ButtonSave();
        }
      },
      onSelect: model => {
        switch (model) {
          case "Toggle":
            this._toggle.state === Position.allow ? toggleCmpView.deniedToggle() : toggleCmpView.allowToggle();
            return true;
          case "Button":
            setVisitorMode(this._toggle.state === Position.allow ? "enabled" : "disabled");
            sendPianoAnalytic(
              "click.action",
              {
                feature: "cmp_mesure_audience",
                click: "enregistrer",
              },
              {
                cmp_analytics: (this._toggle.state === Position.allow).toString(),
              }
            );
            onButtonSave();
            return true;
        }
      },
      horizontal: false,
      pageSize: 2,
      visibleBefore: 1,
      visibleAfter: 1,
      spatialFocus: false,
    });
  }
}

export class MoreInformationcCmp extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, "MoreInformationcCmp"));
    DOMHelper.createDivWithParent(
      this.rootElement,
      "Title",
      null,
      "Les traceurs de mesure d'audience Médiamétrie et AT internet"
    );
    const text = [
      "Notre site utilise des traceurs de mesure d’audience afin de mesurer l’audience de nos services et de nos contenus (les statistiques peuvent être par exemple : le nombre de visites, de pages consultées, le temps passé sur le site, la région de connexion, le type d'appareil et de navigateur utilisés, etc.).",
      "Conformément à l’article 82 de la loi Informatique et Libertés, complété par la délibération de la CNIL n°2020-091 du 17 septembre 2020, ces traceurs peuvent, sous certaines conditions, entrer dans la catégorie des traceurs strictement nécessaires au fonctionnement de nos services et bénéficier d’une exemption de consentement.",
      "En consentant au dépôt et à la lecture des traceurs de mesure d’audience, vous nous permettez de mesurer l'audience des contenus présents sur le site internet ou l’application utilisé et d'en améliorer le fonctionnement à partir des données de navigation et de profil vous concernant.",
      "En ne consentant pas au dépôt des traceurs de mesure d’audience, notre mesure d’audience est limitée aux critères définis par la CNIL pour être strictement nécessaire au bon fonctionnement de nos services et donc exemptée de consentement ; ainsi, les données utilisées ne permettent que la production de statistiques de visite de nos sites et contenus anonymes.",
      "Quels sont les traceurs de mesure d’audience exemptés de consentement et comment exercer votre droit d’opposition pour ce navigateur et terminal ?",
      "Le traceur « MEDIAMETRIE » de Médiamétrie mesure les audiences des contenus vidéo. Il permet notamment à France Télévisions de collecter les informations nécessaires à la rémunération des ayants droit par les sociétés de gestion collective, conformément au code de la propriété intellectuelle.",
      "La rémunération des ayants droit constituant un motif légitime et impérieux au sens de l’article 21 du Règlement général sur la protection des données, France Télévisions ne donne pas automatiquement la possibilité de s’opposer à ce traceur.",
      "Vous pouvez néanmoins faire valoir les raisons tenant à votre situation particulière pour demander à vous opposer à ce traceur en envoyant un email à l’adresse dpd@francetv.fr<br>Le traceur « AT internet » mesure l’audience de nos sites et applications.<br>Vous pouvez vous y opposer en cochant la case ci-dessous : « Je m’oppose au traceur de mesure d’audience AT Internet » .",
      "L’opposition au dépôt de traceurs de mesure d’audience exemptés de consentement peut entrainer le dépôt d’un traceur signalant votre opposition. Ce traceur n’entraîne pas de collecte de donnée et sera conservé pendant une durée de treize mois sur le navigateur et le terminal utilisé.",
    ];
    DOMHelper.createDivWithParent(this.rootElement, "TextMoreInformationcCmp", null, text.join("\n\n"));
    const menu = DOMHelper.createDivWithParent(this.rootElement, "Menu", null, null);
    this.delegate = createListComponent(
      {
        rootElement: menu,
        modelSource: new StaticModelSource<"Arrow" | "List">(["Arrow", "List"]),
        viewFactory: model => {
          switch (model) {
            case "Arrow":
              return new Arrow();
            case "List":
              return new ListToggle(() => {
                navigationStack.removePage(this);
              });
          }
        },
        horizontal: true,
        pageSize: 2,
        visibleBefore: 1,
        visibleAfter: 1,
        spatialFocus: false,
        onSelect: this._onItemSelect,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }

  private _onItemSelect(item: string) {
    switch (item) {
      case "Arrow":
        (() => {
          const arrow = document.getElementById("ArrowImage");
          const text = document.getElementById("TextMoreInformationcCmp");
          if (arrow && text) {
            switch (text.style.top) {
              case "":
                text.style.top = TextPosition.middle;
                break;
              case TextPosition.top:
                text.style.top = TextPosition.middle;
                break;
              case TextPosition.middle:
                text.style.top = TextPosition.low;
                arrow.style.transform = "rotate(180deg)";
                break;
              case TextPosition.low:
                text.style.top = TextPosition.top;
                arrow.style.transform = "rotate(0deg)";
                break;
            }
          }
        })();
        return true;
      default:
        return false;
    }
  }
}
